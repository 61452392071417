<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BrunaForm ref="fechaConsultaRef" :fields="this.prediccion.fields" :card="this.prediccion.card" :datePickerFormatOptions="datePickerFormatOptions">
          <template v-slot:footer>
            <b-button variant="primary" class="float-right" @click="updateCharts()">Consultar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <bruna-element-loader :dataUrl="chart1"></bruna-element-loader>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <bruna-element-loader :dataUrl="chart2"></bruna-element-loader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table ref="tablaHistoricoGlobal" :configUrl="historicoPrediccionesConfig" :helper="true" :dataUrl="historicoPrediccionesData"></ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";
import axios from "@axios";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue';
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue';
var qs = require('qs');


export default {
  data() {
    //fecha ultimo registro, los meses van desde el 0 al 11 
    const last_date = new Date(2022,11,31);
    const default_fin = last_date;
    const default_inicio = new Date(last_date.getFullYear(), last_date.getMonth(), last_date.getDate() - 30);
    let query1 = qs.stringify({ inicio: default_inicio, fin: default_fin, faena: 'PLANTA QUIMICA DE LITIO CARMEN' });
    let query2 = qs.stringify({ inicio: default_inicio, fin: default_fin, faena: 'Salar Atacama' });

    return {
      chart1: useApiServices.historicoPrediccionesChart + "?" +  query1,
      chart2: useApiServices.historicoPrediccionesChart + "?" +  query2,
      historicoPrediccionesConfig: useApiServices.historicoPrediccionesConfig,
      historicoPrediccionesData: useApiServices.historicoPrediccionesData,
      itemsData: [],
      datePickerFormatOptions: {'year': 'numeric', 'month': '2-digit', 'day': '2-digit'},
      prediccion: {
        card: {
          title: "Elige la fecha que deseas consultar",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Predicciones',
            body: "<p>Para escoger el período que desea visualizar, seleccione los rangos de fechas y luego haga clic en “consultar”.<p/>"
          }
        },
        fields: [
          {
            label: "Fecha inicio",
            type: "datepicker",
            id: 'fecha_inicio',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: default_inicio
          },
          {
            label: "Fecha fin",
            type: "datepicker",
            id: 'fecha_fin',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: default_fin
          },
        ]
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    BrunaElementLoader
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },
  methods: {
    refreshInfo() {
      this.updateCards();
      // this.$refs.tablaHistoricoCarga.refreshRows();
    },

    updateCards() {
      axios.get(useApiServices.historicoPrediccionesStatistics)
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    },

    updateCharts() {
      const default_inicio = this.prediccion.fields[0].value;
      const default_fin = this.prediccion.fields[1].value;
      let query1 = qs.stringify({ inicio: default_inicio, fin: default_fin, faena: 'PLANTA QUIMICA DE LITIO CARMEN' });
      let query2 = qs.stringify({ inicio: default_inicio, fin: default_fin, faena: 'Salar Atacama' });

      this.chart1= useApiServices.historicoPrediccionesChart + "?" +  query1;
      this.chart2= useApiServices.historicoPrediccionesChart + "?" +  query2;
    }
  },
};
</script>

<style scoped></style>
