<template>
  <section id="predicciones">
    <b-tabs v-model="tabIndex">
      <!-- Tab incidentes -->
      <b-tab title="Última Predicción">
        <historico-incidentes-tab></historico-incidentes-tab>
      </b-tab>
      <!-- Tab predicciones -->
      <b-tab title="Histórico Predicciones ">
        <historico-predicciones-tab></historico-predicciones-tab>
      </b-tab>      
    </b-tabs>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import HistoricoPrediccionesTab from "./HistoricoPrediccionesTab.vue";
import HistoricoIncidentesTab from "./HistoricoIncidentesTab.vue";

export default {
  data() {

    let activeTabName = this.$route.params.active_tab_name;
    let tabIndex = 0;

    if (activeTabName == "Última Predicción") {
      tabIndex = 0
    }

    if (activeTabName == "Histórico Predicciones") {
      tabIndex = 1
    }

    return {
      tabIndex: tabIndex
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,

    HistoricoPrediccionesTab,
    HistoricoIncidentesTab,
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },

  methods: {
  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

